.topNavContainer {
  nav {
    z-index: 9;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10000000149011612);
    .open-user-menu {
      rotate: 180deg;
    }
  }
  .menuToggle {
    width: 40px;
    height: 40px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    margin-right: auto;
    transition: 350ms;
    .menuIcon {
      width: 40px;
      height: 40px;
      display: block;
      background-image: url('data:image/svg+xml,<svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.66699 5.94727H17.5003" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.66699 10.9473H17.5003" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.66699 15.9473H17.5003" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.5 5.94727H2.50833" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.5 10.9473H2.50833" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.5 15.9473H2.50833" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center;
      z-index: 100;
    }
    &::before {
      content: "";
      position: absolute;
      left: -250px;
      top: -250px;
      width: 150vw;
      height: 150vh;
      background: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(1px);
      display: none;
    }
  }
  .TopNavAction {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .userDetails {
    padding: 0rem 0.625rem;
  }
  .userNameWrap {
    font-size: 0.8rem;
    line-height: 1.1;
  }
  .userRoleWrap {
    font-size: 0.75rem;
    color: $secondary_grey;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiCard-root {
    display: flex;
    flex-direction: row-reverse;
    max-width: 100%;
    padding-right: 1.5rem;
  }
  .app-bar {
    .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
      padding-left: $one_rem !important;
      padding-right: $one_rem !important;
    }
  }
  .topnav-profileimg {
    box-shadow: 0px 0px 1.5px 0px $primary_blue;
    padding: 1.5px;
    border-radius: 50%;
    z-index: -1;
    .form-image-top-nav{
      img{
        object-fit: contain !important;
      }
    }
  }

  .top-nav-tab {
    width: 45%;
    margin-inline: 240px auto;
  }
  .tab-margin {
    margin-left: 65px;
    border-left: 1px rgba(151, 151, 151, 0.4) solid;
    padding-left: $one_rem;
  }
}

.header-profile-menu {
  .MuiMenu-paper {
    overflow-x: visible !important;
    overflow-y: visible !important;    
  }
}

.select-portco-menu {
  position: absolute;
  left: -139px;
  top: 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  .menu-item-container {
    width: 140px;
    max-height: 350px;
    overflow: auto;
    list-style: none;
    padding-left: 0;
    .select-portco-menu-item {
      padding: 8px 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}


@media screen and (max-width: 992px) {
  .topNavContainer {
    .top-nav-tab {
      margin-left: 0;
    }
  }

  .menu-active {
    .leftnav-main {
      left: 0px;
    }
    .topNavContainer .menuToggle {
      margin-left: 220px;
      .menuIcon {
        background-image: url('data:image/svg+xml,<svg viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 6L5.5 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.5 6L16.5 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      }
      &::before {
        display: block;
      }
    }
  }
}
@media screen and (max-width: 475px) {
  .menu-active {
    .topNavContainer .menuToggle {
      margin-right: -90px;
      transition: 300ms;
    }
  }
}

@media screen and (min-width: 993px) {
    .topNavContainer {
      .menuToggle {
        display: none;
      }
    }
  
}
