.add-container {
    display:inline-block;
    border-radius: 5px;
    border: 1px;
    padding: 1rem;

    .add-label {
        font-family: $default_font_family;
        font-weight: 600;
        font-size: 1rem;
        padding: 0.5rem;
    }

    .add-input .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
        height: 2.3rem;
        margin-right: 0.5rem;
    }
}