.editContractContainer {
	overflow: hidden;
	.editContracttHeader {
			display: flex;
			justify-content: space-between;
	}

	.editContractFormGroup {
			width: 100%;
			max-width: 540px;
			padding: 10px 16px 16px 16px;
			margin: 0px auto;
			border-style: solid;
			border: 1px solid #e0e0e0;
			align-self: baseline;
			border-radius: 0.4rem;       
	}
	.uploadyourdocumentdiv{
			padding-top: 0.7rem;
			.MuiInputBase-inputSizeSmall{
					padding-top: 7px;
					padding-bottom: 7px;
					font-weight: normal;
					font-size: 0.85rem;
					color: rgba(0, 0, 0, 0.87);
			}
			.label-bg{
					background-color: #fff;
					padding-left: 0.25rem;
			}
			.disable-bgdropdown{
					border-color: #b6b6b6;
					background: #FFF!important;
					border-radius: 5px;
			}
	}

	.uploadselect {
			border-width: 0.063rem;
			border-style: solid;
			border-color: rgba(0, 0, 0, 0.12);
	}

	.saveButton{
			width: 5.813rem;
			height: 2.25rem;
			padding-top: 0px;
			padding-bottom: 0px;
	}

	.editCancelButton{
			@extend .saveButton;
			border-width: 0.063rem;
			border-style: solid;
			border-color: #b6b6b6;
	}

	.cancelFinishButtonSection{
			@extend .dragdropzone;
			display: flex;
			justify-content: flex-end;
	}
		.select-align{
			margin-left: 0.313rem;
		}
		.contractListPaperWrapper{
			height: 37.5rem;
		}
}