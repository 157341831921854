.accordion-custom {
    .accordion-title {
        margin: 0;
    }
    .MuiButtonBase-root.accordion-header {
        background: $accordion_background;
        min-height: 48px;
        .MuiAccordionSummary-content.Mui-expanded {
            margin: 12px 0;
        } 
    }
}

.accordion-checkbox {
    width: 100%;
    .accordion-title {
        margin: 0;
        margin-left: 30px;
    }
    .MuiAccordionSummary-expandIconWrapper {
        position: absolute;
        left: 15px;
    }
    .accordion-checkbox-field {
        position: absolute;
        right: 15px;
        top: 4px;    
        visibility: visible;
    }
    .permissions-list {
        padding-left: 0;
        border: 1px solid $gray-02;
        list-style: none;
        li{    
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $gray-02;
            padding-left: 16px;
            align-items: center;
            &:last-child {            
                border-bottom: 0;
            }
        }
    }
}