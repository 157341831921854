.contract-search {
  width: 100%;
  padding: $one_rem;
  display: flex;
  justify-content: flex-end;
  .searchWrapper {
    width: 40%;
    margin-right: $one_rem;
  }
  .contractTypeWrap {
    width: 25%;
    margin-right: $one_rem;
  }
  .contractTypeWrap.date-field .MuiFormControl-root {
    margin-right: 0px;
  }
  button.MuiButton-sizeMedium,
  button.searchButtonWrap {
    padding-top: 4px;
    padding-bottom: 3px;
  }
}

@media (max-width: 650px) {
  .forresponsive-search {
    width: 100%;
    display: block;
    .searchWrapper {
      width: 100%;
      margin-bottom: $one_rem !important;
    }
    .contractTypeWrap {
      width: 100%;
      margin-bottom: $one_rem !important;
    }
  }
}

.contractListContainer {
  .page-header {
    display: flex;
  }
  .selection-row {
    display: flex;
    justify-content: space-between;
    margin-left: $one_rem;
    margin-top: 10px;
    margin-bottom: 10px;
    .heading-contract-details {
      display: flex;
      flex-direction: row;
      gap: $one_rem;
    }
  }
  .delete-reanalyze-selected {
    display: flex;
    align-items: baseline;

    .MuiFormControl-root {
      display: -webkit-inline-box !important;
    }
    .icon-size {
      height: 20px;
      vertical-align: text-top;
    }
    .icon-disabled {
      @extend .icon-size;
      color: $gray-black-25;
    }
    .icon-enabled {
      @extend .icon-size;
      color: $primary_blue;
    }
    .reanalyze-icon {
      height: 20px;
      vertical-align: text-top;
    }

    .reanalyze-icon-disabled {
      color: $gray-black-25;
    }

    .reanalyze-icon-disable {
      opacity: 0.3;
    }

    .reanalyze-icon-enabled {
      color: $primary_blue;
    }
  }
  .upload-button {
    justify-content: flex-end;
    margin-right: 40px;
  }
  .selection-count {
    margin-left: $one_rem;
  }
  .contractListWrapper {
    background-color: $white_background !important;
    border-radius: 0.625rem;
    padding: 1.563rem;
    height: 100vh;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .contractListHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: $one_rem;
    align-items: center;
  }
  .tableContainerWrap,
  .contract-list-table {
    min-height: 66vh;
  }
  .table-cell-bold {
    font-weight: bold;
  }
  .contractListIcon {
    color: $default_icon_color;
  }
  .emptyContractList {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    font-size: 1.5rem;
  }
  .viewIconWrap {
    cursor: pointer;
  }
  .textEllipses {
    text-overflow: ellipsis;
  }
  .tooltipWidth {
    width: 300px;
  }
}
.MuiTable-root {
  tbody th,
  tbody td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .contract-title {
    min-width: 220px;
    max-width: 220px;
  }
  .contract-type {
    min-width: 180px;
    max-width: 180px;
  }
  .contract-description-cell {
    min-width: 220px;
    max-width: 220px;
  }
}
.contractListActionContainer {
  ul li {
    font-size: 13px;
  }
  svg {
    font-size: 1.125rem;
  }
  .actionMenuItemWrap {
     
  }
  .deleteIconWrap {
    @extend .actionMenuItemWrap;
    color: red;
  }
  .reanalyzeIconWrap {
    @extend .actionMenuItemWrap;
    color: $black;
  }
  .actionMenuItemIcon {
    margin-right: 10px;
  }
  .actionMenuItemText {
    margin-right: 50px;
    color: $black;
  }
  .tableCell {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tabelCell:hover {
    white-space: normal;
    overflow: visible;
  }
}
.contract-list-table {
  .MuiTableCell-root.right-padding-none,
  table tr td:first-child,
  table thead tr th:first-child {
    padding-right: 0;
    padding-left: 10px;
  }
  .MuiTableCell-root {
    padding: 3px 10px;
  }
  .MuiTableRow-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.loaderwaper {
  display: flex;
  position: relative;
  width: 100%;
  .loader-main {
    position: absolute;
    left: 50%;
    top: 100px;
    margin-left: -25px;
  }
}
.dateArrow {
  margin-top: 3px;
  font-size: 35px !important;
  margin-right: 12px;
}
.buttonHeight {
  height: 38px;
}
.contractlisttable {
  .contract-search-main {
    box-shadow: none;
  }
  .MuiTable-root tbody tr td:first-child,
  .MuiTable-root thead tr th:first-child {
    padding-left: 8px;
  }
}
  .contract-title-width {   
    width: 450px;
    padding-left: 35px;
  }
  .truncate-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    width: 180px;
}
.m-10{
  margin:10px;
}
