.contract-terms-list{
    .MuiTable-root thead tr th:nth-last-child(2){
        min-width: 100px;
    }
    .MuiTable-root thead tr th:last-child{
        min-width: 80px;
    }
}
.add-terms-container {
    min-height: 75vh;
    .add-terms-grid {
        margin-left: 0 !important;
        width: 100% !important;
        .show-source {
            margin-top: -5px;
        }
        .MuiGrid-item {
            padding-left: 0 !important;
        }
    }
}
.form-buttons{
    display: flex; 
    width: 100%;
    justify-content: flex-end;
}
.contract-terms-page{
    table.MuiTable-root th, table.MuiTable-root td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 42px;
    } 

.termlabel-cell{
    max-width: 130px;
    min-width: 130px;
}   
.terminfo-cell{
    max-width: 130px;
    min-width: 130px;
}
.termorderno-cell{
    max-width: 80px;
    min-width: 80px;
}
.contract-cell{
    max-width: 150px;
    min-width: 150px;
}
.method-cell{
    max-width: 80px;
    min-width: 80px;
}
.instructions-cell{
    max-width: 130px;
    min-width: 130px;
}
}
.nav-contract-button{
    position: fixed !important;
    top: 50%;
    background-color: gray !important;
    box-shadow: 1px 1px 3px gray;
}
.next-contract-term{ @extend .nav-contract-button;
    right: 0.5rem;
}
.previous-contract-term{@extend .nav-contract-button; 
    left: 245px;
}
.arrow-icon{
    color: white;
}
.contract-terms-selection, .screening-terms-container{
    table.MuiTable-root th, table.MuiTable-root td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 42px;
        } 
    .checkbox-select {
        display: flex;
        align-items: baseline;
        .MuiFormControl-root {
            display: -webkit-inline-box !important;
        }
        .icon-size {
            height: 20px;
            vertical-align: text-top;
        }
        .icon-disabled {
            @extend .icon-size;
            color: $gray-black-25;
        }
        .icon-enabled {
            @extend .icon-size;
            color: $primary_blue;
        }
        .selection-count {
            margin-left: $one_rem;
        }
    }
}