.feedback-page {
    min-height: 75vh;
    .contract-search{
        padding-bottom: 20px;
        .contractTypeWrap.date-field .MuiFormControl-root{
            margin-right: 0px;
        }
    }
    .MuiTable-root .MuiTableCell-root{
        padding-top: 11px;
        padding-bottom: 11px;
    }
    .contract-title-feedback{
        min-width: 180px;
        max-width: 180px;
    }
    .contract-term-value{
        min-width: 170px;
        max-width: 170px;
    }
    .contract-feedback{
    min-width: 150px;
    max-width: 150px;

    }
    .feedbackTitle{
    padding-left: 19px;
    padding-bottom: 0px;
    font-size: x-large;
    font-weight: 500;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    button{
        position: absolute;
        top: -130px;
        right: 0;
    }

    }
}


@media (max-width: 650px) {
    .feedback-page {
        button.feedbackButtonExport-r{
            position: absolute;
            top: -51px;
            right: 0;
        }
    
        }
    }
