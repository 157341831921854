// Upload functinality
.form-control-file {
  display: flex;
  align-items: center;
}
.form-control-file3 {
  background-color: #fff;
  border: 1.5px solid $primary_blue;
  color: $primary_blue;
  position: relative;
  text-align: center;
  padding: 3px 5px;
  font-weight: 500;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  transition: 500ms;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }
}

.form-fileview {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0px 18px 0 0;
  border: 1px solid #7a8af1;
  box-shadow: 0 0 0 1px #e3e3e7;
  .image-upload-text{
    width: 100%;
  }
  .form-image {
    position: relative;
    width: 110px;
    height: 110px;
    z-index: 3;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }
  }
}

.add-user-loader {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.user-list-page {
  table {
    min-width: 965px;
    thead tr th:nth-child(2),
    tbody tr td:nth-child(2) {
      display: none;
    }
  }
  .emptyContractList {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    font-size: 1.5rem;
  }
  .tableContainerWrap {
    min-height: 69vh;
  }
  .MuiTable-root .MuiTableCell-root {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .role-cell {
    min-width: 130px;
    max-width: 130px;
  }
  .modified-by {
    min-width: 150px;
    max-width: 150px;
  }
}
.user-info {
  display: flex;
  align-items: center;

  .user-avatar {
    margin-right: 10px;
  }
}
.act-inct-title {
  font-size: 13px;
  color: #7e7e7e;
  margin: 0;
  padding: 10px 0px 0px 15px;
  font-weight: 400;
}
.contractListActionContainer .MuiMenu-paper {
  min-width: 170px;
  .active-inactive-item {
    justify-content: space-between !important;
  }
}

.user-select-role {
  fieldset {
      legend {
          width: 63px;
      }
  }
}