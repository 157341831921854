.MuiPaper-root .accordion-custom {
    box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
    .MuiCollapse-root .execute-label,
    .MuiCollapse-root .else-label,
    .MuiCollapse-root .value-label {
        margin-bottom: 10px;
        margin-top: 0;
    }
    .MuiCollapse-root .if-response {
        margin-bottom: 0px;
        margin-top: 5px;
    }
}