*{
    box-sizing: border-box;
}

.text-center{text-align: center;}
.dispaly-inlineblock{
    display: inline-block;
}
.text-muted{color: #777;}
body {
    margin: 0;
    width: 100%;
    min-height: 100vh;
    font-family: $default_font_family !important;
    background-color: #f5f5f5;
    font-size: 0.875rem;
}
h1, h1.h1-title{
    font-size: 28px;
    font-weight: 500;
    margin: 0;
    padding: 0;
}
h2, h2.h2-title{
    font-size: 22px;
}
.container {
    padding-top: 5rem;
    padding-left: $one_rem;
    padding-right: $one_rem;
    padding-bottom: $one_rem;
    max-width: 100%;
}
.containerWithOutTopPadding {    
    padding-left: $one_rem;
    padding-right: $one_rem;
    padding-bottom: $one_rem;
    max-width: 100%;
}
.display-flex {
    display: flex;
}
.page-title{
    display: flex;
    justify-content: space-between;
    margin-bottom: $one_rem;
    align-items: center;
}
.container-right {
    float:right
}
.errorField{
    border-color: red($color: $black) !important;
  }
  .rightmain-container{
    width: 100%;
    padding-left: 240px;
  }
  .MuiTable-root{
    .MuiSvgIcon-root{
        font-size: 16px;
    }
    .MuiTableCell-root{
    padding: 5px 8px;
    }
    tbody tr td:first-child, thead tr th:first-child {
        padding-left: 20px;
    }
    .MuiTableCell-body{
        font-size: 13px;
    }
    .date-cell{
        min-width: 130px;
        max-width: 130px;
    }
    .extra-width-cell {
      min-width: 220px;
      max-width: 220px;
    }
    .modifiedby-cell{
        max-width: 115px;
        min-width: 115px;
    }
    .createdby-cell{
        max-width: 100px;
        min-width: 100px;
    }
    .action-cell{
        min-width: 70px;
        max-width: 70px;
    }   
    .row-grey {
        background-color: $gray-00;
      }
      .row-fore-red span {
        background-color: $light-red;
        padding: 5px 0;
        color: $alert-red;    
        font-weight: bold;
        border-radius: 4px;
        min-width: 75px;
        text-align: center;
        display: inline-block;
      }
    .row-fore-green span {
        background-color: $light-green;
        padding: 5px 0;
        color: $primary_success; 
        font-weight: bold;
        border-radius: 4px;       
        min-width: 75px;
        text-align: center;
        display: inline-block;
      }
    .edit-icon{
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        padding: 0;
        svg{
            margin-right: 5px;
        }

    }
}
.MuiTablePagination-root{
.MuiTablePagination-selectLabel, .MuiSelect-select, .MuiTablePagination-displayedRows{
    font-size: 13px;
}
}
button.common-disablebutton{
    min-width: 90px;
}
button.common-disablebutton.Mui-disabled{
    background-color: $light-blue !important;
    color: $white_background !important;
    font-weight: 400;
    min-width: 90px;
}
button.commoncancelbutton{
    border: 1px solid $gray-02;
    font-weight: 400;
    color: $black;
    min-width: 90px;
    
}
button.commoncancelbutton.mr-20px{
    margin-right: $one_rem;
    display: inline-block;
}
.form-buttons {
    .mr-20px {
        margin-right: $one_rem;
    }
}
.MuiFormControl-root .MuiSelect-select{
    color: $black;
}
ul.MuiList-padding{
    padding-top: 0px;
    padding-bottom: 0px; 
}
.position-relative{
    position: relative;
}

.emptyContractList {
    width: 100%;
    text-align: center;
    line-height: 40px;
    color: $gray-03;
}
.wrapper-class{
    padding:1rem ;
    border: 1px solid $gray-03;
}
.editor-class{
    padding:1rem ;
    border: 1px solid #989898;
}
.toolbar-class{
    border: 1px solid #989898;
}
.App {
    font-family: sans-serif;
    text-align: center;
  }

.MuiButton-root.only-icon {
    color: $black;
    padding: 9px 0 9px 10px;
    margin-left: 20px;
    }
    
.MuiButton-root.no-bg {
    background: transparent;
    border: 1px solid $gray-02;
    &:hover {
        -webkit-text-decoration: none;
        text-decoration: none;
        background-color: rgba(43, 56, 143, 0.04);
        border: 1px solid $secondary_grey;
    }
}

.MuiButtonBase-root.clone {
    background-color: $green-background;
    &:hover{
        background-color: $green-hover;
    }
}

.error-fallback{
    padding: 20px; 
    background-color: $light-red;
}

.main-paper {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1000000015) !important;
    min-height: 74vh !important;
    height: auto !important;
    position: relative;
    .MuiTableContainer-root {
        .MuiTable-root {    
            min-width: 980px !important;
        }
    }
    .tooltip-style {
        width: 1222px;
        background: red;
    }
    .tableContainerWrap {
        min-height: 60vh;
    }

    .emptyContractList {
        display: flex;
        justify-content: center;
        margin-top: 5rem;
        font-size: 1.5rem;
    }
}

.loader-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    z-index: 100;
    position: fixed;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loader-page-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    z-index: 3;
    position: fixed;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader-center.loader-with-text {
    flex-direction: column;
    .loader-message {
        background: #fff;
        border-radius: 5px;
        gap: $one_rem;
        padding: $one_rem;
        width: 70%;
        min-width: 350px;
        text-align: center;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        line-height: 25px;
    }
}

.preview-modal {
    h2 {
        padding: $one_rem;
    }
    .MuiDialogContent-root {
        padding: $one_rem;
        .preview-modal-heading {
            min-height: 48px !important;
            .MuiAccordionSummary-content.Mui-expanded {
                margin: 12px 0;
            }
        }
    }
}

.email-version-container {
    .MuiTableContainer-root.tableContainerWrap {
      min-height: 75vh;
    }
}

.ai-icon {
    position: absolute;
    top: 7px;
    right: $one_rem;
    font-weight: bold;
    text-transform: capitalize;
    color: #fff;
    text-shadow: 1px 2px 10px black;
    background: rgb(89,172,64);
    background: -moz-linear-gradient(157deg, rgba(89,172,64,1) 0%, rgba(43,56,143,1) 100%);
    background: -webkit-linear-gradient(157deg, rgba(89,172,64,1) 0%, rgba(43,56,143,1) 100%);
    background: linear-gradient(157deg, rgba(89,172,64,1) 0%, rgba(43,56,143,1) 100%);
    padding: $half_rem;
    border-radius: 4px;
  }
  .ai-icon.modal {
    top: 12px;
    right: 4rem;
  }

  .w-350 {
    width: 350px;
  }

  .select-helper-label{
    background-color: #fff;
  }

  .reg-module-message-popup{
    width: 550px !important;
  }
  
@media (min-width: 600px) {
    .MuiContainer-root {
        padding-left: $one_rem !important;
        padding-right: $one_rem !important;
    }
}

@media (max-width: $regular) { 
    .contract-search .w-350 {
        width: 300px;
    }
}

@media (max-width: 992px) { 
    .form-buttons {
        .previous-contract-term {
            left: 0.5rem;
        }
    }
}
@media (max-width: $medium) {
    .assign-user-tab {
        .search-textfield {
            width: 17rem !important;
        }
    }
    .assign-user-tab {
        .assign-unassign-count {
            white-space: nowrap;
        }
    }
    .text-editor {
        fieldset {
            min-height: 280px;
        }
    }
    .email-view-version-popup {
        .text-editor {
            fieldset {
                min-height: 210px !important;
            }
        }
    }
}

@media (max-width: $small) {
    .heading-contract-details {
        flex-direction: column !important;
        gap: 0 !important;
    }
    .contractListContainer {
        .selection-row {
            flex-direction: column;
        }
    }
    .change-password-buttons {
        flex-direction: column !important;
        gap: $one_rem;
    }
    .assign-user-page {
        .selection-row {
            flex-direction: column;
        }
    }
    .createEmail {
        .preview-template {
          line-height: $one_rem;
        }
        .text-editor {
            fieldset {
                min-height: 330px;
            }
        }
      }
      .email-view-version-popup {
        .mid-popup-contant {
            height: auto !important;
            max-height: unset !important;
        }
        .dialogTitle {
            width: 72%;
        }
        .text-editor {
            fieldset {
                min-height: 240px !important;
                .editor-actions {
                    button {
                        line-height: $one_rem;
                    }
                }
            }
        }
      }
    .contract-search {
        .w-350 {
            width: 70%;
            min-width: 270px;
        }
    }
    .topNavContainer {
        .userDetails {
            display: none;
        }
    }
    .topNavContainer {
        .tab-margin {
            margin-left: 0 !important;
            border-left: 0 !important;
            padding-left: 0 !important;
        }
    }
    .top-nav-tab {
        button {    
            min-width: 60px;
        }
    }
    .assign-user-tab {
        .selection-row {
            gap: 1rem;
            flex-wrap: wrap;
        }
        .assign-unassign-count {
            gap: 5px !important;
        }
    }
    .dialog-container {
        .dialogActions {
            width: 100% !important;
        }
    }
    .contractDetailsContainer {
        .backbutton {
            display: none !important;
        }
        .contract-details-attachments {
            .reanalyz-btn {
                margin-top: 10px;
            }
        }
        .contract-detail-content {
            flex-direction: column;
            .pdfContainer, .contract-details {
                height: 45vh;
            }
            .MuiGrid-grid-xs-8, .MuiGrid-grid-xs-4 {
                max-width: 100%;
                flex-basis: 100%;
                width: 100%;
            }

        }
    }
}

@media (max-width: $x-small) {
    .contract-search {
        .w-350 {
            width: 50%;        
            min-width: 130px;
        }
    }
    .mr.display-flex {
        margin: 0 !important;
    }
}
.InfoIcon {
    color: $info_icon_blue !important;
    font-size: 16px !important;
}
.appVersion{
    position: absolute;
    bottom: 3px;
    width: calc(100% - 20px);
    text-align: center;
    padding-bottom: 2px;
    font-size: 0.7rem;
    background: #fff;
}  
.action-button-profile {
    display: flex;
    gap: $one_rem;
}
.visibility-hidden {
    visibility: hidden;
}
.mui-label-background{
    label.MuiFormLabel-root{
        background-color: #fff;
        padding-right: 5px;
    }
}
.text-align-right {
    text-align: right;
}
.chips-input{
    .chip-input{
        .chip{
            margin: 5px 2.5px;
        }
        .MuiInputBase-adornedStart{
            flex-wrap: wrap;
        }
    }
}
.float-left {
    float: left;
}
.sync-value {
    float: left;
    padding-left: 5px;
}
.sync-box {
    min-width: 192px; 
    float: right;
}
.screeningTermContainer {
    .selection-row {
      display: flex;
      justify-content: space-between;
      margin-left: $one_rem;
      margin-top: 10px;
      margin-bottom: 10px;
    }
}
.info-text {
    font-size: 11px;
    color: $gray-04;
    font-style: italic;
}
.InfoIcon-gray {
    .icon {
        color: $gray-04;
        font-size: 16px;
        position: relative;
        bottom: -4px;
    }
}

#menu-appbar {
    ul.MuiMenu-list {
        padding-top: 8px;
    }
}