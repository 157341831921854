.contract-details-attachments {
  .emptyContractList {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    font-size: 1.5rem;
  }
.add-type-container {
        min-height: 75vh;
    
    }
.remove-attach-button {
    display: ruby-text;
    align-items: center;
    margin-top: -19px;
}
button.remove-attach-btn {
    width: 5.813rem;
    height: 2.1rem;
    margin-left: 0.65rem;
    font-weight: 400;
    font-size: 0.85rem;
    padding-bottom: 0.29rem;
  }
  .reanalyz-btn {
    display: flex;
    justify-content: end;
    margin-top: -39px;
    align-items: center;
  }
  .precedence-icon{
    margin-left: -9px;
  }
  .reanalyze-icon{
    margin-left: -5px;
  }
  .contract-title {
    min-width: 220px;
    max-width: 220px;
  }
  .contract-type {
    min-width: 180px;
    max-width: 180px;
  }
  .contract-description-cell {
    min-width: 220px;
    max-width: 220px;
  }
  .remove-attachment-link {
    margin-right: -6px;
  }
}

.attachment-list-page {
  table {
    min-width: 965px;
  }
  .tableContainerWrap {
    height: 69vh;
  }
  .MuiTable-root .MuiTableCell-root {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .role-cell {
    min-width: 130px;
    max-width: 130px;
  }
  .modified-by {
    min-width: 150px;
    max-width: 150px;
  }
}