.uploadNewContractContainer {
  margin-bottom: 4rem;
  .MuiPaper-elevation {
    box-shadow: none;
    border: 1px solid #e0e0e0;
    border-radius: 0.35rem;
  }

  .uploadNewContracttHeader {
    display: flex;
    justify-content: space-between;
  }
  .uploadContractFormGroup {
    border-style: solid;
    border: 1px solid #e0e0e0;
    border-radius: 0.4rem;
    width: 100%;
    max-width: 540px;
    padding: 16px 0px;
    margin: 0px auto;

    font-weight: normal;
    .uploadyourdocumentdiv {
      .select-align label {
        font-weight: normal;
        font-size: 0.85rem;
        background: #fff;
        padding: 0px 3px;
      }
      .MuiContainer-maxWidthXl {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    .css-1gzkxga-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input:focus {
      background: #fff;
      border-radius: 0.3rem;
    }
  }

  .uploadyourdocumentDescription {
    fieldset {
      border-color: #b6b6b6;
      border-radius: 0.3rem;
      font-size: 0.85rem;
      font-weight: normal;
    }
    textarea {
      font-weight: normal;
      font-size: 0.85rem;
    }
  }

  .uploadnewcontracttextfield {
    // width: 37.5rem;
    label {
      font-size: 0.85rem;
      font-weight: normal;
    }
    label.fileSizeText {
      font-size: 0.7rem;
      margin-bottom: 0.8rem;
      display: inline-block;
      color: #777;
    }
  }

  .dragdropzone {
    @extend .uploadnewcontracttextfield;
    padding-top: 0.313rem;
    // padding-left: 0.75rem;
  }

  .uploadselect {
    border-width: 0.063rem;
    border-style: solid;
    border-color: #b6b6b6;
    border-radius: 0.3rem;
    font-size: 0.85rem;
    background-color: #fff !important;
    .MuiInputBase-inputSizeSmall {
      padding-top: 7px;
      padding-bottom: 7px;
      font-weight: normal;
      font-size: 0.85rem;
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .document-textfields {
    label {
      font-size: 0.85rem;
      font-weight: normal;
    }
    .MuiInputBase-formControl {
      border-radius: 0.3rem;
      font-size: 0.85rem;
    }
    fieldset {
      border-color: #b6b6b6;
      border-radius: 0.3rem;
      font-size: 0.85rem;
      // margin-left: 2.5px;
      // width: 100.35%;
    }
  }

  button.uploadFinishButton {
    width: 5.813rem;
    height: 2.4rem;
    margin-left: $one_rem;
    font-weight: 400;
    font-size: 0.85rem;
  }

  .uploadCancelButton {
    width: 5.813rem;
    height: 2.4rem;
    font-weight: normal;
    font-size: 0.85rem;
    border-width: 0.063rem;
    border-style: solid;
    border-color: #b6b6b6;
    font-weight: 400;
    color: #000;
  }

  .cancelFinishButtonSection {
    @extend .dragdropzone;
  }

  .checkboxTextSpace {
    @extend .dragdropzone;
    .MuiPaper-rounded {
      padding: 0px !important;
    }
    button.dragDropBrowse {
      padding: 4px 30px;
      border-radius: 0.3rem;
      height: auto;
      font-weight: normal;
      font-size: 0.85rem;
    }
    div.MuiPaper-root {
      border: 1.5px dashed $primary_blue !important;
      background: #f6f7ff !important;
      border-radius: 0.3rem;
      width: 100%;
    }
  }
  .common-topheading {
    font-size: 1.2rem;
    color: #19191a;
    margin-top: 0px;
  }

  .fileUploadResponseMsg {
    @extend .dragdropzone;
    text-align: center;
    color: red;
  }
}

.contractType {
  align-items: center;
}

.uploadyourdocumenttext {
  font-weight: bold;
  font-size: 1.5rem;
}

.documentTitle {
  font-weight: normal;
  font-size: 1.2rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.divContentAlign {
  display: flex;
}
.cancelFinishButtonSection-end .MuiGrid-grid-xs-12 {
  display: flex !important;
  justify-content: flex-end !important;
}

.typography-p {
  align-self: center;
  font-size: 1.1rem !important;
  color: #0c0c0c;
  margin-bottom: 0px;
}

label.fileSize {
  padding-bottom: 0.25rem;
  margin-top: -10px;
  display: inline-block;
  color: #333;
  font-size: 1rem !important;
  padding-bottom: 0.4rem;
}

label.fileSizeText {
  padding-top: 0.55rem;
  font-size: 0.7rem;
}

.selectedFileName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  font-size: 0.8rem;
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid $gray-03;
  border-radius: 5px;
  padding-left: $one_rem;
}

.contractType-container {
  font-weight: normal;
  font-size: 1.2rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  /* vh stands for viewport height */
}

.rounded-input {
  border: radius 10px;
  border: 1px solid #ccc;
  padding: 5px;
}
.success-message {
  color: $primary_success;
  margin-bottom: 0px;
  margin-top: 5px;
}

.error-message {
  color: red;
  text-align: center;
}

.error-message-profile-icon {
  color: red;
  text-align: left;
  font-size: 0.75rem;
  margin-top: 5px;
}
.success-message-profile-icon {
  color: $primary_success;
  margin-bottom: 0px;
  margin-top: 5px;
  font-size: 0.75rem;
  text-align: left;
}

.invalid-file-type {
  font-size: 0.7rem;
  margin-top: 0.7rem;
  margin-bottom: 0.8rem;
  display: inline-block;
  color: red;
}
