.dialog-container-withList { 
    .dialogActions {
    padding: 20px;
    padding-top: 0px;
    border-radius: 8px;
    border: 1px;
    width: 400px;
    box-sizing: border-box;
    }


  .dialogTitle {
    font-family: $default_font_family;
    font-size: 18px; 
    font-weight: 700; 
    line-height: 24px;
    text-align: left;
    strong{
      span{
        color: $dailog-gray;
      }
    }
  }
  .dialogSecandTitle {
    font-family: $default_font_family;
    font-size: 18px; 
    font-weight: 700; 
    line-height: 24px;
    margin: 0px 0px 16px 0px;
    text-align: center;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .listDataContent
  {
    cursor: pointer;;
    background-color:transparent;
    border: none;
    color: $primary_blue;
    text-align: left;
  }
  .liList{
    padding: 7px 0px; 
    text-align: left;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    margin: 0px 8px;
    &:last-child{
      border-bottom: none;
    }
  }
  .uiList{
    list-style-type : none;    
    padding: 0;
    margin: 0;
    text-align: left;
  }
  .listDataDiv {
    max-height: 160px;    
    height: auto;
    overflow: auto;
    border: 1px solid #eee;
    border-radius: 6px;
  }
  
  .dialog-button {
    margin-top: 50px;
  }
  
  .dialog-button .MuiDialogActions-root {
    justify-content: center;
    padding-top: 2px;
  }

  .dialog-button .MuiDialogActions-root button {
    font-family: $default_font_family;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 4px;
    background: $primary_blue;
    color: $white_background;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.11999999731779099);
  }

  .dialog-button .MuiDialogActions-root button.button-color { 
    background: $white_background;
    color: $default_text_color; 
  }
}