.pre-processing-container {
  
.tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px; /* Space between tags */
  }
  
  .tag-span {
    background-color: #d5d4d4; 
    border-radius: 12px;
    padding: 4px 8px; 
    font-size: 0.875rem; 
  }

  .status-container{
    display: flex;
  }

  .status-inprogress-icon{
    color: $primary_blue;
    font-size: 20px;
  }
  .status-completed-icon{
    color: $green-background;
    font-size: 20px;
  }
  .status-failed-icon{
    color: $alert-red;
    font-size: 20px;
  }
  .checkbox-select {

    display: flex;
    align-items: baseline;

      .MuiFormControl-root {
        display: -webkit-inline-box !important;
      }

      .icon-size {
        height: 20px;
        vertical-align: text-top;
      }

      .icon-disabled {
        @extend .icon-size;
        color: $gray-black-25;
      }
      
      .icon-disable {
        @extend .icon-size;
        opacity: 0.3;
      }

      .icon-enabled {
        @extend .icon-size;
        color: $primary_blue;
      }

  }

  
.tags-cell {
  position: relative;
  overflow: visible;
}

  .more-tags-container {
    display: inline-block;
    position: relative;
  }
  
  .more-dots {
    cursor: pointer;
    color: $gray-04;
  }
  
  .tooltip {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $box-background-light;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px $box-shadow-dark;
    max-height: 200px;
    overflow-y: auto;
    width: 250px;
    padding: 8px;
    z-index: 10;
    border-radius: 5px;

  }
  
  .more-tags-container:hover .tooltip {
    display: block;
  }
  
  .tooltip-tag {
    padding: 4px;
    border-bottom: 1px solid #eee;
  }
  
  .tooltip-tag:last-child {
    border-bottom: none;
  }
}