.change-password {
    min-height: 75vh;

    .chnagepass-title {
        font-size: 16px;
        font-weight: normal;
        margin: 0px 0px 15px 0px;
        padding: 0;
    }
    .password-error {
        color: #f80101;
    }
    .password-success{
        color: #1e4620;
    }
    .password-icon-comb {
        width: 100%;
        position: relative;
        margin: 0px 0px 20px 0px;
    }
    .password-icon {
        width: 40px;
        position: absolute;
        right: 20px;
        top: -2px;
    }
}


.password-hitns {
    padding-left: 50px;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0px 0px 15px 0px;

        li {
            font-size: 13px;
            list-style-type: none;
            padding: 0;
            margin: 0;
            line-height: 23px;
        }
    }
}