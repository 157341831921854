.leftnav-main{
  width: 240px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10000000149011612);
  .MuiDrawer-paper {
    position: static;
    border: none;
    box-shadow: none;
  }
  .logo {
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 20px;
    img {
      height: 45px;
    }
  }
}
.drawer-container {
  width: 100%;
}
.leftNavContainer {
  padding:0 10px;
  hr{
    display: none;
  }
  .appLogoWrapper {
    margin: 0 auto;
  }
  .linkWrapper {
    display: block;
    color: $black;
    text-decoration: none;
    font-size: 0.813rem;
    font-weight: 500;
  }
  .contractManagementWrap {
    color: $secondary_grey;
    text-align: left;
    font-size: 0.813rem;
    padding: 0;
    margin-bottom: 10px;
  }
  .menuList{
    padding:0 0 15px 0;
    border-bottom: 1px solid #E0E0E0;
    margin: 0 0 15px 0;
  }
  .leftNavLinkItem {
    padding: 0;
  }
  .leftNavList.MuiListItemButton-root{
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.813rem;

  }
  .leftNavList.MuiListItemButton-root.Mui-selected {
    background-color: $primary_blue;
    color: $white_background;
    
  }
  .selectedLeftNavIcon{
    color: $white_background;
    margin-right: 0.625rem;
    font-size: 1.125rem;
  }
  .leftNavIcon{
    margin-right: 0.625rem;
    font-size: 1.125rem;
  }
  .leftNavDivider{
    width: 100%;
    margin: 0px auto;
    display: none
  }
  .subMenuAccordionWrap{
    @extend .linkWrapper;
    box-shadow: none;
    .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters{
      margin: 0;
      height: 2.25rem;
      min-height: 2.15rem;
      border-radius: 4px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters:hover{
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .subMenuAccordionName{
    padding-top: 0.15rem;
  }
  .subMenuLinkWrapper {
    display: block;
    color: $black;
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 400;
  }
  .subMenuSelectedLeftNavIcon {
    @extend .selectedLeftNavIcon;
    font-weight: 400;
  }
  .subMenuLeftNavIcon {
    @extend .leftNavIcon;
    font-weight: 400;
  }
  .subMenuAccordionSummary .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
  .expandIconWrapper{
    font-size: 1rem;
  }
  .MuiCollapse-vertical .MuiAccordionDetails-root{
    padding: 0;
    svg{
      font-size: 14px;
      margin-left: 3px;
      margin-right: 10px;
    }

  }
}


@media screen and (max-width: 992px){
  .leftnav-main{
    left: -250px;
    transition: 350ms;
  }
  .rightmain-container {
    padding-left: 0px;
  }
}
.hide_class{
  display: none !important;
}