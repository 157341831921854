.verification-waper{
    width:400px;
    margin: 0 auto;
  }
  .login-logo{
    width: 100%;
    display: flex;
    justify-content: center;
    img{
        height: 60px;
        width: auto;
    }
  }
  .verification-container {
    width:100%;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(158, 158, 158, 0.25);
    padding: 30px;
  }
 
  .verification-container h1{
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    margin: 0px 0px 10px 0px;
    padding: 0;
  }
  .verification-container p{
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    margin: 0px 0px 20px 0px;
    padding: 0;
  }
  .verification-form{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .verification-form .verificationcode,  .verification-form {
    width: 100%;
    margin: 0px 0px 20px 0px;
  }
  .verification-form .verificationcode input,  .verification-form input{
    font-size: 14px;
    font-weight: 400;
  }
  .verification-form label{
    font-size: 14px;
    font-weight: 400;
 
  }
  .verification-form button{
    width: 100%;
    margin: 0px 0px 20px 0px;
   
  }