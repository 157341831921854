.password-reset-container {
    width:400px;
    margin: 0 auto;
    position: relative;

    .loadering-img{
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
      justify-content: center;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 20px;
      bottom: 0;
      right: 0;
      align-items: center;
      background: rgb(255 255 255 / 15%);
    }

  .login-logo{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top:50px;
    img{
        height: 60px;
        width: auto;
    }
  }
  .password-reset {
    width:100%;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(158, 158, 158, 0.25);
    padding: 30px;
  }
 
  .password-reset h1{
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    margin: 0px 0px 20px 0px;
    padding: 0;
    .h1-title{
        text-align: center;
    }
  }

  .password-reset h2{
    text-align: center;
    margin: 0px 0px 20px 0px;
    padding: 0;
  }

  .password-reset-container p{ 
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    margin: 0px 0px 20px 0px;
    padding: 0;
  }
  .password-reset-form{
    display: grid;
    width: 100%;
    flex-wrap: wrap;
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0px 0px 15px 0px;
      li{
      font-size: 13px;
      list-style-type: none;
      padding: 0;
      margin: 0;
      line-height: 23px;
      strong {
        font-weight: 500;
        
      }

    }
  }
  }
  .password-reset-form .loginemail,  .password-reset-form .loginpassword{
    width: 100%;
    margin: 0px 0px 15px 0px;
  }

  .password-rest-instruction{
    color: #4A4A4A;
  }

  .password-icon-comb{
    width: 100%;
    position:relative;
    display: inline-block;
  }
  .password-icon{
    width: 10px;
    position:absolute;
    right: 20px;
  }
  .password-continue-to-login{
    display: ruby-text;
  }
  .password-error{
    color: #f80101;
  }
  .password-success{
      color: #063414;
  }
}
.forgot-success-msg{
  .rest-success-msg{
    margin-bottom: 20px;
  }
 .login-form button{
  margin-bottom: 0;

}
}