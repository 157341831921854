.portco-setting{
   h2{
    font-size: 22px;
    text-align: center;
    margin-top: 0px;
    padding: 0px;
    font-weight: 500;
   }
   .login-container{
    padding: 20px 30px 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 400px;
    
    .middle{
        width: 100%;
        display: block;
    }
    .protco-loader{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99999;

    }

   }
   .portco-drodown{
    margin-bottom: 10px;
    margin-top: 10px;
    ul li:first-child {
        display: none;
        }
   }
   
}