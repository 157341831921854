.contractTypeContainer {
    .contractListPaperWrapper {
        padding: 27px 0px 24px 24px;
    }
    .contractType-container{
        fieldset{
            border: 1px solid #b6b6b6;
        }
    }
    .contractTypeWrapper {
        background-color: $white_background !important;
        border-radius: 0.625rem;
        padding: 1.563rem;
        height: 100vh;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
    }

    .contractTypeDetails {
        background-color: $white_background !important;
        border-radius: 0.625rem;
        padding: 1.563rem;
        height: 15vh;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
    }

    .contractTypeHeader {
        display: flex;
        justify-content: space-between;
        h1{
            font-size: 1.75rem;
            font-weight: 500;
            margin: 0px;
            padding-top: 0rem;
            padding-bottom: 1rem;
        }
    }

    .contractTypeWrap {
        margin-left: 1rem;
        min-width: 20rem;
    }

    .searchWrapper {
        min-width: 30rem;
    }

    .searchButtonWrap {
        min-width: 10rem;
        margin-left: 1rem;
    }

    .tableContainerWrap {
        height: 59vh;
    }    
    .table-cell-bold{
        font-weight:bold;
    }
    .contractListIcon {
        color: $default_icon_color;
    }
    .emptyContractList {
        display: flex;
        justify-content: center;
        margin-top: 5rem;
        font-size: 1.5rem;
    }
    .viewIconWrap {
        cursor: pointer;
    }
    .users-table {
        width: 100%;
        border-collapse: collapse;
    }
    
    .users-table-header {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 2px solid #ddd;
        font-weight: bold;
    }
    
    .users-table-body {
        display: flex;
        flex-direction: column;
    }
    
}
.tabelCell:hover{
    white-space: normal;
    overflow: visible;
}
.css-cpgvjg-MuiSnackbar-root{
    position: relative !important;
}

.add-type-container {
    min-height: 75vh;

}
.contract-type-page {
    table.MuiTable-root th, table.MuiTable-root td{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    } 

}
.contract-type-page{
.contract-cell{
    max-width: 200px;
    min-width: 200px;
}
.condescription-cell{
    max-width: 250px;
}
}

@media (max-width: 1400px) {
    .add-type-container {
        min-height: 74vh;
    }
}