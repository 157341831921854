.contractTermWrapper {
  background-color: $white_background !important;
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  padding-top: 0.2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contractDetailsLeftWrapper {
  border-radius: 0.25rem 0 0 0.25rem !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.pdfContainer {
  overflow: auto;
  height: 85vh;
  border-radius: 0.25rem 0 0 0.25rem !important;
  canvas {
    margin: 0 auto;
  }
}

.contractDetailsRightWrapper {
  border-radius: 0 0.25rem 0.25rem 0 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 85vh;
}

.contract-details {
  overflow: hidden;
  overflow-y: auto;
  padding: 20px 15px;
  height: 85vh;
  padding-bottom: 50px;

  h3.detail-sub-heading {
    font-weight: 600;
    font-size: 1.15rem;
    margin: 0 0px 20px 0px;
    padding: 0;
  }
  h3.detail-sub-heading.contract-terms-heading {
    margin-top: 55px;
  }
  .value-row {
    margin-bottom: $one_rem;
    h5.value-title {
      font-weight: 600;
      font-size: 0.875rem;
      margin: 0px 0px 5px 0px;
      padding: 0;
    }
    .term-value {
      font-size: 0.813rem;
      margin-bottom: 6px;
      color: #000;
      line-height: 21px;
    }
  }
  .grid-container {
    display: flex;

    .detail-col {
      width: 50%;
    }
  }

  .align-right {
    text-align: right;
  }

  .information-button {
    padding: 4px;
  }
}
.preProcessingContractDetailsRightWrapper{
  position: relative;
  .contract-details{
    padding-bottom: 5px !important;
  }
}
.Mui-to-tooltip {
  font-size: 14px;
}

.exportToCsvButton {
  height: 1.5rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  border-radius: 0.625rem;
  margin-left: auto;
}
.detailpage-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.contractDetailsContainer {
  position: relative;

  .heading-no-button {
    margin-top: 2px;
  }
  
  .backbutton {
    color: $primary_blue;
    display: flex;
    align-items: center;
    position: fixed;
    top: 23px;
    left: 260px;
    z-index: 9;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
    svg {
      font-size: 14px;
    }
  }
  .back-arrow-detail {
    display: inline-block;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $primary_blue;
    margin-right: 0.3rem;
    cursor: pointer;
  }
  .header-with-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 10px;
  }
  .page-title {
    padding-top: 15px;
    margin-left: 15px;
  }
}

@media screen and (max-width: 992px) {
  .backbutton {
    left: 85px !important;
    z-index: -1;
  }
}
.source-para {
  background: #f3f3f3;
  transition: 300ms;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 0;
  box-shadow: 0px 2px 4px 0px $gray-black-25;
  margin-top: 7px;
  font-size: 0.813rem;
  line-height: 21px;
}
.btn-source {
  background: transparent;
  color: $primary_blue;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.feedack-modal {
  position: relative;
  .feedback-button {
    position: absolute;
    top: -5px;
    right: 0px;
    padding: 5px;
  }
}
.feedback-heading {
  margin-top: -10px;
  display: flex;
  align-items: center;
  padding-bottom: 0px !important;
  strong {
    margin-left: 3px;
    font-size: 1.2rem;
  }
  svg {
    color: #e74b4b;
    margin-left: -5px;
    font-size: 1.4rem;
  }
}
.feedback-para {
  margin-top: 10px;
  font-size: 0.8rem;
  margin-bottom: 15px;
  margin-top: 6px;
  color: #000;
}
.uploadnewcontracttextfield {
  legend {
    height: none;
  }
}
.uploadnewcontracttextfield.user-feedback-field {
  margin-top: 0.6rem;
}
.justify-content-end {
  display: flex;
  justify-content: flex-end;
}
.buttontext-size {
  button {
    font-weight: 400 !important;
    font-size: 14px !important;
  }
}
.cancel-button {
  border: 1px solid #ccc !important;
  box-shadow: none !important;
}
.contractDetailsContainer {
  .MuiGrid-grid-xs-8 {
    max-width: 65%;
    flex-basis: 65%;
    width: 65%;
  }
  .MuiGrid-grid-xs-4 {
    width: 35%;
    flex-basis: 35%;
    max-width: 35%;
  }
}
.contractpage {
  position: relative;
}
.contractDetailsTermContainer {
  .page-header {
    display: flex;
  }
  .MuiTableContainer-root.tableContainerWrap {
    
    min-height: 75vh;
  }
}
