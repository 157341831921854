.roles-and-permissions-container {
    .tableContainerWrap {
        min-height: 75vh;
    }
}
.roles-and-permissions-search {
    width: 100%;
    padding: $one_rem;
    display: flex;
    justify-content: flex-end;
    .searchWrapper {
      width: 40%;
      margin-right: 20px;
    }
}

.roles-permissions {
    .MuiTabs-flexContainer{
        border-bottom: 1px solid $gray-02;
    }
    .module-heading {
        padding-left: 15px;
        margin-block-end: 0px;
    }
    .button-row {
        display: flex;
        flex-direction: row;
        .button {
            min-width: 5.813rem;
            height: 2.4rem;
        }
        .secondary-button {
            font-weight: normal;
            font-size: 0.85rem;
            border-width: 0.063rem;
            border-style: solid;
            border: 1px solid $gray-00;
            font-weight: 400;
            margin-right: 10px;
            background: none;
            color: $black;
            &:hover {
                border: 1px solid $primary_blue
            }
        }
        .primary-button {
            margin-left: 0.65rem;
            font-weight: 400;
            font-size: 0.85rem;
            margin-right: 10px;
        }
    }
}