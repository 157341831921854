.dialog-container {
   
  .dialogActions {
  border-radius: 8px;
  border: 1px;
  width:400px;
  padding: 0px 20px 20px 20px;
  box-sizing: border-box;
  }


.dialogTitle {
  font-family: $default_font_family;
  font-weight: 600; 
  margin: 0px 0px 10px 0px;
  text-align: center;
  strong.message-textpara{
    font-size: 1.34rem;
    margin: 0px 0px 0px 0px;
  }
  div.message-textpara-small{
    font-size: 1rem;
    margin: 0px 0px 0px 0px;
  }
  strong{
    span{
      color:$dailog-gray;
    }
    p{
      text-align: left!important; 
      font-size: 1.2rem;
      line-height: 25px;
      margin: 0;
      padding: 0;
    }
  }
}

  .dropdown-wrapper{
    display: flex;
    justify-content: center;
  }

  .dialog-title-text{
    margin-bottom: 1rem;
  }

  .dialog-button {
    margin-top: 50px;
  }
  .dialog-button .MuiDialogActions-root {
    justify-content: center;
    padding-top: 12px;
  }

.dialog-button .MuiDialogActions-root button {
  font-family: $default_font_family;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 4px;
  background: $primary_blue;
  color: $white_background;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.11999999731779099);
  min-width: 90px;
}

.dialog-button .MuiDialogActions-root button.button-color { 
  background: $white_background;
  color: $default_text_color; 
}
}